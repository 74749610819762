.main-header {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #E7B15B;
  /* position : fixed; */
}

.main-header .container {
	background-color: #E7B15B;
	border: none;
}

.main-header .dropdown-menu {
	background-color: #E7B15B;
	border: none;
}

.main-header .dropdown-menu > li > a {
	padding: 7px 16px;
	color: #fff;
	font-size: 13px;
	font-weight: bold;
	line-height: normal;
}

.main-header .dropdown-menu > li > a .fa {
	margin-right: 5px;
	font-size: 16px;
}

.main-header .dropdown-menu > li:hover > a, 
.main-header .dropdown-menu > li > a:hover, 
.main-header .dropdown-menu > li > a:focus, 
.main-header .dropdown-menu > li.active > a {
	background-color: #E7B15B;
}

.animation, .animation a {
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.text-bold {
  font-weight: 700;
}

.location-contact {
  color: #fff;
}

.top-links {
	margin-bottom: 5px;
}

.top-links li + li {
	padding-left: 5px;
}
.top-links li a {
	color: #fff;
}

.btn-dropdown-angkor {
  color: #ffe6d1;
  background-color: #E7B15B;
  border-color: #ffe6d1;
  border-style: none;
  padding-left: 6px;
  padding-right: 6px;
}

.navbar-text-angkor {
  color: #ecf0f1;
}

.badge-angkor {
  color: #ffe6d1;
  background-color: #E7B15B;
  top: -7px;
}


.navbar-nav > li {
  float: left;
  position: relative;
}

.order-info {
	margin-bottom: 0;
}
.order-info li {
	color: #fff;
}
.order-info li.lead {
	margin-bottom: 0;
	font-size: 24px;
	font-weight: bold;
}
.order-info li.lead .fa {
	margin-right: 10px;
	font-size: 22px;
}
.order-info li.lead, 
.order-info li.lead .fa {
	vertical-align: middle;
}
.order-info li .btn-light {
	padding: 5px 20px;
}


.navbar-light .navbar-nav .selected a::after {
  border-bottom: 5px solid #5bc0eb;
  bottom: -10px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
}

.navbar .navbar-nav .nav-link {
  transition: all .05s ease-in-out;
}

.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 5px solid #FF0000;
}

.navbar-light .navbar-nav .nav-link:hover {
  border-bottom: 5px solid #FF0000;
}

.navbar-toggler:hover {
  cursor: pointer;
}

.navbar-angkor {
  background-color: #E7B15B;
  border-color: #DAA95A;
  padding: 0.5rem 0.5rem;
}

.navbar-angkor .navbar-brand {
  color: #ecf0f1;
}

.navbar-angkor .navbar-brand:hover, .navbar-angkor .navbar-brand:focus {
  color: #ffe6d1;
  cursor: pointer;
}

.navbar-angkor .navbar-nav > li > a {
  color: #ecf0f1;
}

.navbar-angkor .navbar-nav .nav-link.active {
  color: #cf3d27;
  font-weight: bold;
  border-bottom: 5px solid #cf3d27;
}

.navbar-angkor .navbar-nav .nav-link:hover {
  border-bottom: 5px solid #cf3d27;
}

.navbar-angkor .navbar-nav > li > a:hover, .navbar-angkor .navbar-nav > li > a:focus {
  color: #cf3d27;
  font-weight: bold;
}

.navbar-angkor .navbar-nav .active > a, .navbar-angkor .navbar-nav .active > a:hover, .navbar-angkor .navbar-nav .active > a:focus {
  color: #ffe6d1;
  background-color: #DAA95A;
}

.navbar-angkor .navbar-nav .open > a, .navbar-angkor .navbar-nav .open > a:hover, .navbar-angkor .navbar-nav .open > a:focus {
  color: #ffe6d1;
  background-color: #DAA95A;
}

.navbar-angkor .navbar-nav .open > a .caret, .navbar-angkor .navbar-nav .open > a:hover .caret, .navbar-angkor .navbar-nav .open > a:focus .caret {
  border-top-color: #ffe6d1;
  border-bottom-color: #ffe6d1;
}

.navbar-angkor .navbar-nav > .dropdown > a .caret {
  border-top-color: #ecf0f1;
  border-bottom-color: #ecf0f1;
}

.navbar-angkor .navbar-nav .dropdown-menu .dropdown-item {
  color: #ecf0f1;
}

.dropdown-item:hover {
  background-color: #DAA95A;
}

.navbar-angkor .navbar-nav > .dropdown > a:hover .caret, .navbar-angkor .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #ffe6d1;
  border-bottom-color: #ffe6d1;
}

.navbar-angkor .navbar-toggler {
  border-color: #ecf0f1;
}

.navbar-angkor .navbar-toggler:hover, .navbar-angkor .navbar-toggler:focus {
  background-color: #DAA95A;
}

.navbar-angkor .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(236,240,241, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#shopping-cart-angkor .shopping-cart-badge[data-count]:after{
  position:absolute;
  right:10%;
  top:5%;
  content: attr(data-count);
  font-size:50%;
  padding:.2em;
  border-radius:50%;
  line-height:1em;
  color: white;
  background:rgba(255,0,0,.85);
  /* background:rgba(255,0,0,.5); */
  text-align:center;
  min-width: 1em;
  
}