body {
  font-size: .875rem;
  height: 100vh;
  /* background-color: #e4e5e6 */
}

#menu-section-list {
  position: fixed;
}

[role="main"] {
  /* padding-top: 16px; */
  padding-left: 16px;
  padding-right: 16px;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.required:after {
  content:" *";
  color: red;
}