@media (max-width: 767px) {
  .main-content {
    margin-top: 60px;
    margin-left: 0px;
    /* margin-bottom: 30px; */
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .main-content {
    margin-top: 60px;
    margin-left: 100px;
    /* margin-bottom: 30px; */
  }
}

@media (min-width: 1200px) {
  .main-content {
    margin-top: 60px;
    margin-left: 250px;
    /* margin-bottom: 30px; */
  }
}

.btn-angkor {
  color: #ffe6d1;
  background-color: #daa95a;
  border-color: #ffe6d1;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23DAA95A' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23DAA95A' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators li {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 5px;
  text-indent: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #ffe6d1;
  box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.carousel-indicators .active {
  width: 24px;
  height: 24px;
  margin: 5px;
  background-color: #daa95a;
}

.bg-angkor {
  background-color: #daa95a;
}

.card-angkor {
  border-color: #ffe6d1;
}

.card-angkor .card-header {
  background: none repeat scroll 0 0 #daa95a !important;
  color: #ecf0f1;
}

.collapse-angkor > a {
  display: block;
  position: relative;
}

.collapse-angkor > a:after {
  content: "\f078"; 
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 0;
}

.collapse-angkor > a[aria-expanded="true"]:after {
  content: "\f077"; 
}

.clickable-card:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  background-color: #daa95a;
  cursor: pointer;
}

.clickable-row:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  /* background-color: #cecbc5; */
  cursor: pointer;
}

.clickable:hover {
  cursor: pointer;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}


.btn-circle:hover {
  cursor: pointer;
}


.btn-circle-facebook {
  background-color:  #4267b2;
}

.btn-circle-twitter {
  background-color:  #1DA1F2;
}

.btn-circle-email {
  background-color:  rgb(164, 167, 168);
}

.clickable:hover {
  cursor: pointer;
}

.dashed-line {
  border: none;
  border-top: 1px dotted gray;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.promotion-label {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #5ac15e;
  border-radius: 9px;
  display: inline-block;
  padding: 1px 10px;
}
.removable-label {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #d7e0d7;
  border-radius: 0px;
  display: inline-block;
  padding: 1px 10px;
}

.removable-label i {
  cursor: pointer;
}

.highlight-text {
  color: red
}